import Button from '@/components/form/button'
import ButtonIcon from '@/components/form/buttonIcon'

import Logo from '@/components/icons/logoWhite'
import ArrowCircleIcon from '@/components/icons/arrowCircleRight'
import BagIcon from '@/components/icons/bag'
import Facebook from '@/components/icons/social/facebook'
import Instagram from '@/components/icons/social/instagram'
import Discord from '@/components/icons/social/discord'
import Twitter from '@/components/icons/social/twitter'

import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'Header',

  components: {
    Button,
    ButtonIcon,
    Facebook,
    Instagram,
    Discord,
    Twitter,

    Logo,
    ArrowCircleIcon,
    BagIcon
  },

  data() {
    return {
      value: 99,
      options: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: true
        },
        progress: {
          color: '#0626EB',
          backgroundColor: '#000',
          inverted: false
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line'
        }
      }
    }
  },

  methods: {
    showPickSneakerColor: function() {
      EventBus.$emit('showPickSneakerColor');
    },

    showCart: function() {
      EventBus.$emit('showCart');
    }
  },

  mounted() {
    /*
    let interval = setInterval(() => {
      if(this.value >= 100)
        clearInterval(interval);
      else
        this.value += 1;
    }, 10);
    */
  }
}
