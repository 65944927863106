export default {
  name: 'HomeStep',

  props: {
    number: {
      type: String,
      required: true
    }
  }
}
