import axios from 'axios'
import api from '@/config/apiServer.js'

export default {
  methods: {
    getBannerData: async function() {
      let response;

      await axios
        .get(api + '/home/banner')
        .then(res => response = res)
        .catch(err => console.log(err));

      return (typeof response !== 'undefined' && response.status === 200)
        ? response.data
        : null;
    }
  }
}
