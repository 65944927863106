import Flickity from 'vue-flickity'
import Button from '@/components/form/button'
import ButtonIcon from '@/components/form/buttonIcon'
import Step from '@/components/home/step'
import Loader from '@/components/loader'

import VolumeIcon from '@/components/icons/media/volume'
import VolumeOffIcon from '@/components/icons/media/volumeOff'

import home from '@/mixins/home.js'
import { Mixins } from '@/mixins/bgImg.js'
import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'Home',

  mixins: [
    home,
    Mixins
  ],

  components: {
    Loader,
    Flickity,
    Button,
    ButtonIcon,
    Step,

    VolumeIcon,
    VolumeOffIcon
  },

  data() {
    return {
      loader: true,
      shoePassAudio: false,
      isNextBanner: false,

      bannerSliderOpt: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true
      },

      banner: {
        images: [],
        title: '',
        subtitle: '',
        action: ''
      },

      examplesSliderOpt: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        groupCells: '80%',
        autoPlay: 2 * 1000
      },

      examples: [
        require('@/assets/img/examples/01.jpg'),
        require('@/assets/img/examples/02.jpg'),
        require('@/assets/img/examples/03.jpg'),
        require('@/assets/img/examples/04.jpg')
      ]
    }
  },

  methods: {
    getBanner: async function() {
      let response = await this.getBannerData();

      if (response !== null) {
        this.banner = response;
      } else {
        this.$toast.error('No se pudo obtener la información del banner');
      }
    },

    showPickSneakerColor: function() {
      EventBus.$emit('showPickSneakerColor');
    },

    nextBannerSlide: function() {
      if (!this.isNextBanner) {
        this.isNextBanner = true;

        setTimeout(() => this.isNextBanner = false, 500);
      }
    },

    switchShoePassAudio: function() {
      this.shoePassAudio = !this.shoePassAudio;
    }
  },

  mounted: async function() {
    await this.getBanner();

    await this.bgImg();

    /*
    setTimeout(() => {
      this.loader = false;
    }, 1000);
    */
  },

  watch: {
    isNextBanner: function(val) {
      if (val) {
        this.$refs.homeBanner.next();
      }
    },

    shoePassAudio: function(val) {
      this.$refs.shoepass.muted = !val;
    }
  }
}
